import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import PublicRoute from './Components/PublicRoute';
import EmailValidate from './Views/Login/EmailValidate';
const Login = lazy(() => import('./Views/Login'));
const ForgotPassword = lazy(() => import('./Views/Login/ForgotPassword'));
const ResetPassword = lazy(() => import('./Views/Login/ResetPassword'));
// const DefaultLayout = lazy(() => import('./Containers/DefaultLayout'));
const DefaultLayout = lazy(() => import('./Containers/NewLayout_v2'));
const NotFound = lazy(() => import('./Containers/NotFound'));
const ConnectionError = lazy(() => import('./Containers/ConnectionError'));

function App() {
  const Loading = () => <div className='animated fadeIn pt-1 text-center'>Loading...</div>;

  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Switch>
          <PublicRoute name='Email Validate' path='/email/validate' component={EmailValidate} />
          <PublicRoute name='Reset Password' path='/reset-password/validate' component={ResetPassword} exact />
          <PublicRoute name='Forgot Password' path='/forgot' component={ForgotPassword} exact />
          <PublicRoute name='Login' path='/login' component={Login} />
          {/* <Route
              exact
              path='/change-password'
              name='Change Password'
              render={(props) => <ChangePassword {...props} />}
            /> */}
          {/* <PublicRoute exact path='/' name='Landing Page' component={LandingPage} /> */}
          <Route exact path='/404' name='Page 404' render={(props) => <NotFound {...props} />} />
          <Route exact path='/err/network' name='Network Error' render={(props) => <ConnectionError {...props} />} />
          {/* <Route exact path='/500' name='Page 500' render={(props) => <Page500 {...props} />} /> */}
          <Route path='/' name='Home' render={(props) => <DefaultLayout {...props} />} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default Sentry.withProfiler(App);
