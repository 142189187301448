// import { createRef, StrictMode } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import configureStore from './redux/store';
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';
// import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import 'react-toastify/dist/ReactToastify.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'flatpickr/dist/themes/light.css';
import './styles/index.scss';

import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

const package_json = require('../package.json');

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_ENVIRONMENT,
  release: package_json.version,
});

const { store, persistor } = configureStore();
// const wrapper = createRef();

const customTheme = createMuiTheme({
  overrides: {
    MuiInput: {
      input: {
        '&::placeholder': {
          color: 'grey',
          fontSize: 12,
        },
        // 'color': 'white', // if you also want to change the color of the input, this is the prop you'd use
      },
    },
  },
});

render(
  <CacheBuster
    currentVersion={version}
    isEnabled={true} //If false, the library is disabled.
    isVerboseMode={false} //If true, the library writes verbose logs to console.
    loadingComponent={<div>Loading...</div>} //If not pass, nothing appears at the time of new version check.
  >
    <Provider store={store}>
      <ThemeProvider theme={customTheme}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </ThemeProvider>
    </Provider>
  </CacheBuster>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
