import axios from 'axios';
import { toast } from 'react-toastify';
import { getSessionStorage, setSessionStorage, getCookie, setCookie } from '../utils/cookie';

const config = () => {
  return {
    'Accept': 'application/json',
    'locale': 'en',
    'Authorization': `Bearer ${getSessionStorage('token-web-admin')}`,
    'Referer': 'https://dev-admin.qoin.id',
    'X-XSRF-TOKEN': getCookie('xsrf-token'),
  };
};

const configPut = () => {
  return {
    'Accept': 'application/json',
    'Authorization': `Bearer ${getSessionStorage('token-web-admin')}`,
    'Content-Type': 'application/x-www-form-urlencoded',
    'Referer': 'https://dev-admin.qoin.id',
    'X-XSRF-TOKEN': getCookie('xsrf-token'),
  };
};

const configPutJson = () => {
  return {
    'Accept': 'application/json',
    'locale': 'en',
    'Authorization': `Bearer ${getSessionStorage('token-web-admin')}`,
    'Content-Type': 'application/json',
    'Referer': 'https://dev-admin.qoin.id',
    'X-XSRF-TOKEN': getCookie('xsrf-token'),
  };
};

const handleError = (error) => {
  if (!error?.response) return toast.error('Network Error', { autoClose: 4000 });
  console.log(error.response);
  // const getPathId = parseInt(window.location.pathname.split('/').pop());
  const isTokenExpired = /expired/g.test(error?.response?.data?.message?.toString().toLowerCase());
  if (error?.response?.status === 401 || isTokenExpired) {
    // const originalRequest = error.config;
    const formData = new FormData();
    formData.append('RefreshToken', getSessionStorage('refresh-token-web-admin'));
    axios
      .post(`${process.env.REACT_APP_BASE_URL}client-manager-refresh-token`, formData, {
        headers: config(),
      })
      .then(async (response) => {
        if (response?.data?.status === 'success') {
          await setSessionStorage('refresh-token-web-admin', response?.data?.data?.RefreshToken);
          await setSessionStorage('token-web-admin', response?.data?.data?.Token);
        }

        toast.info(
          'Token was expired, refresh your browser now. if this message always show contact the developer for the information.',
          { autoClose: 3000 }
        );
      })
      .catch(async (err) => {
        if (!err?.response) return toast.error('Connection Timeout', { autoClose: 3000 });

        // await toast.warning('Your token was invalid ...', { autoClose: 3000 });

        // setTimeout(() => {
        //   setSessionStorage('refresh-token-web-admin', '');
        //   setSessionStorage('token-web-admin', '');

        //   window.location.href = '/login';
        // }, 3000);
      });
  } else if (error.response?.status === 404 && error.response?.config?.method === 'get') {
    window.location.href = '/not-found';
  } else {
    if (typeof error?.response?.data?.data === 'object' && error?.response?.data?.data?.length)
      error?.response?.data?.data?.map((item) => toast.error(item));
    if (typeof error?.response?.data?.data === 'string') toast.error(error?.response?.data?.data, { autoClose: 3000 });
    else if (error?.response?.data?.message) toast.error(error?.response?.data?.message, { autoClose: 4000 });
    else if (error?.response?.data?.message) toast.error(error?.response?.data?.message, { autoClose: 4000 });
    else toast.error('Something Error', { autoClose: 4000 });
  }
};

export const generateXSRFToken = async () => {
  if (process.env.REACT_APP_DISABLE_CSRF_TOKEN === 'false') {
    const url = `${process.env.REACT_APP_BASE_URL}v2/tok`;
    const response = await axios
      .post(url, null, configPut())
      .then((response) => response)
      .catch(handleError);

    setCookie('xsrf-token', response?.data?.data?._tok);
    /* Untuk pengetesan secara local, ikuti langkah berikut:
      1. Call api generate token csrf di postman
      2. copy token tersebut dan simpan di cookie dengan key xsrf-token
      */
    // setCookie('xsrf-token', 'e9311b85-cdbc-4830-ba8b-cb40dbcff09e');
  }
};

export const apiGet = (url, header = null) => {
  const response = axios
    .get(url, {
      headers: Object.assign(config(), header),
    })
    .then((response) => response)
    .catch(handleError);
  return response;
};

export const apiPost = async (url, body, isUrlEncoded = false, header = null) => {
  await generateXSRFToken();
  const response = await axios
    .post(url, body, {
      headers: isUrlEncoded ? Object.assign(configPut(), header) : Object.assign(config(), header),
    })
    .then((response) => response)
    .catch(handleError);

  return response;
};

export const apiPostCustomAlert = async ({ url, body, isUrlEncoded = false, header = null, alert }) => {
  await generateXSRFToken();
  const response = await axios
    .post(url, body, {
      headers: isUrlEncoded ? Object.assign(configPut(), header) : Object.assign(config(), header),
    })
    .then((response) => response)
    .catch((err) => {
      console.log(err?.response);
      if (!err.response) alert('Network Error');
      else if (err?.response?.data?.message) alert(err?.response?.data?.message);
      else alert('Something Error', { autoClose: 4000 });
    });

  return response;
};

export const apiDelete = async (url, header = null) => {
  await generateXSRFToken();
  const response = await axios
    .delete(url, {
      headers: Object.assign(config(), header),
    })
    .then((response) => response)
    .catch(handleError);

  return response;
};

export const apiPut = async (url, data, isJson = false, header = null) => {
  await generateXSRFToken();
  const response = await axios
    .put(url, data, {
      headers: isJson ? Object.assign(configPutJson(), header) : Object.assign(configPut(), header),
    })
    .then((response) => response)
    .catch(handleError);

  return response;
};
