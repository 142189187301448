import update from 'immutability-helper';
import {
  GET_REPORT_ERROR,
  GET_REPORT_PENDING,
  GET_REPORT_SETTLEMENT_SUCCESS,
  GET_REPORT_TRANSACTION_SUCCESS,
  GET_REPORT_WITHDRAWAL_SUCCESS,
  GET_REPORT_DONATION_SUCCESS,
  GET_REPORT_DONATION_TRANSFER_SUCCESS,
  GET_REPORT_INSURANCE_SUCCESS,
  GET_REPORT_INSURANCE_TRANSFER_SUCCESS,
  GET_REPORT_PENDING_CHILD,
  GET_REPORT_JOURNAL_SUCCESS,
  GET_REPORT_JOURNAL_SUMMARY_SUCCESS,
} from '../actions/actionType';

const initialState = {
  loading: false,
  loadingChild: false,
  donation: [],
  donationTransfer: [],
  insurance: [],
  insuranceTransfer: [],
  transaction: [],
  settlement: [],
  withdrawal: [],
  journal: [],
  journalSummary: {},
  pagination: {},
  error: '',
};

const reportReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case GET_REPORT_PENDING:
      return update(state, {
        loading: { $set: true },
        error: { $set: '' },
      });

    case GET_REPORT_PENDING_CHILD:
      return update(state, {
        loadingChild: { $set: true },
        error: { $set: '' },
      });

    case GET_REPORT_WITHDRAWAL_SUCCESS:
      return update(state, {
        loading: { $set: false },
        withdrawal: { $set: Array.isArray(actions.payload.data) ? actions.payload.data : [] },
        pagination: { $set: actions.payload.paginator || {} },
        loadingChild: { $set: false },
        error: { $set: '' },
      });
    case GET_REPORT_SETTLEMENT_SUCCESS:
      return update(state, {
        loading: { $set: false },
        settlement: { $set: actions.payload.data },
        pagination: { $set: actions.payload.paginator },
        loadingChild: { $set: false },
        error: { $set: '' },
      });
    case GET_REPORT_TRANSACTION_SUCCESS:
      return update(state, {
        loading: { $set: false },
        transaction: { $set: actions.payload.data },
        pagination: { $set: actions.payload.paginator },
        loadingChild: { $set: false },
        error: { $set: '' },
      });

    case GET_REPORT_DONATION_SUCCESS:
      return update(state, {
        loading: { $set: false },
        donation: { $set: actions.payload.data },
        pagination: { $set: actions?.payload?.child ? state.pagination : actions.payload.paginator },
        loadingChild: { $set: false },
        error: { $set: '' },
      });

    case GET_REPORT_DONATION_TRANSFER_SUCCESS:
      return update(state, {
        loading: { $set: false },
        donationTransfer: { $set: actions.payload.data },
        pagination: { $set: actions.payload.paginator },
        loadingChild: { $set: false },
        error: { $set: '' },
      });

    case GET_REPORT_INSURANCE_SUCCESS:
      return update(state, {
        loading: { $set: false },
        insurance: { $set: actions.payload.data },
        pagination: { $set: actions?.payload?.child ? state.pagination : actions.payload.paginator },
        loadingChild: { $set: false },
        error: { $set: '' },
      });

    case GET_REPORT_INSURANCE_TRANSFER_SUCCESS:
      return update(state, {
        loading: { $set: false },
        insuranceTransfer: { $set: actions.payload.data },
        pagination: { $set: actions.payload.paginator },
        loadingChild: { $set: false },
        error: { $set: '' },
      });

    case GET_REPORT_JOURNAL_SUCCESS:
      return update(state, {
        loading: { $set: false },
        journal: { $set: actions.payload.data },
        pagination: { $set: actions.payload.paginator },
        loadingChild: { $set: false },
        error: { $set: '' },
      });

    case GET_REPORT_JOURNAL_SUMMARY_SUCCESS:
      return update(state, {
        loading: { $set: false },
        journalSummary: { $set: actions.payload.data },
        loadingChild: { $set: false },
        error: { $set: '' },
      });

    case GET_REPORT_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: actions.payload },
        donation: { $set: [] },
        donationTransfer: { $set: [] },
        insurance: { $set: [] },
        insuranceTransfer: { $set: [] },
        transaction: { $set: [] },
        settlement: { $set: [] },
        withdrawal: { $set: [] },
        journal: { $set: [] },
        journalSummary: { $set: [] },
        pagination: { $set: {} },
      });

    default:
      return state;
  }
};

export default reportReducer;
